import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const repertoire = [
  { head: 'Joseph Haydn', rows: [{ content: 'Klaviertrio Hob:XV:29' }] },
  {
    head: 'Hans Eisler',
    rows: [{ content: 'Duo für Violine und Violoncello' }]
  },
  { head: 'Jonathan Golove', rows: [{ content: 'Klaviertrio „Bad Dreams“' }] },
  { head: 'Franz Schubert', rows: [{ content: 'Klaviertrio B-Dur op. 99' }] },
  { head: 'Dimitri Schostakovich', rows: [{ content: 'Klaviertrio No 2' }] },
  { head: 'mit Klarinette' },
  {
    head: 'Olivier Messiaen',
    rows: [{ content: 'Quatuor pour la fin du temps' }]
  }
]

const TD = styled.td`
  border: none;
`

const Page = ({ data, ...props }) => {
  console.log(data.picture.edges[0].node.childImageSharp.fluid)
  return (
    <section>
      <b>Trio 99: Kathrein Allenberg Violine, Jonathan Golove Violoncello, Anne-Kathrein Jordan Klavier</b>
      <br />
      <br />
      <Img fluid={data.picture.edges[0].node.childImageSharp.fluid} />
      <br />
      <p>
        <b>Das Trio 99</b> begann seine Zusammenarbeit 2018 mit Franz Schubert's Klaviertrio B-Dur. Das Potenzial zu
        erforschen, das diese klassische Besetzung bietet, und darüberhinaus als Kern-Ensemble offen zu sein für größere
        Kammermusik wie Klavierquartett (mit Klarinette: Messiaen oder Viola) oder Trio mit Gesang (Ravel, Beethoven)
        war Grund genug für das Ensemble, sich auf einen gemeinsamen Weg zu begeben (allen vernünftigen Argumenten wie
        geographische Entfernung oder ausreichender anderweitiger Auslastung der MusikerInnen zum Trotz).
      </p>
      <p>
        <b>Kathrein Allenberg</b> studierte an der Hochschule der Künste Berlin bei Prof. Thomas Brandis, dem
        Konzertmeister der Berliner Philharmoniker.Sie arbeitete mit Lehrern wie E. Hurwitz, D. Zsigmondy, T. Zehetmair
        und I. ArdittiNach mehrere Spielzeiten im Orchester der "Deutschen Oper Berlin" entschied sie sich für eine
        freiberufliche Laufbahn. Die Auseinandersetzung mit der „historischen Aufführungspraxis“ brachte ein reges
        Konzertleben auf der Barockgeige durch Europa mit sich. Lange Jahre führte sie ein Streichquartett auf
        historischen Instrumenten mit „klassischem“ Streichquartettrepertoire. 2011 erschien die CD „Quartette für
        Preußen“ auf Instrumenten des Berliner Musikinstrumentenmuseum.Von 1995 bis 2011 war sie Geigerin des Berliner
        Ensembles für Neue Musik „work in progress - Berlin“ mit temporär eigener Reihe im Kammermusiksaal der Berliner
        Philharmonie. Wiederaufführungen wichtiger Werke der Gegenwartsmusik standen ebenso auf dem Programm wie
        zahlreiche Uraufführungen; z.B. von Philipp Maintz („schneeblind“ für Violine solo), Mark Andre, Isabel Mundry
        u.a.. Sie konzertierte bei den "Darmstädter Tagen für Neue Musik" und anderen Festivals für Neue Musik. Sie war
        1996 Gründungsmitglied des "Kairos Quartetts". 2009 war sie Gast bei György Kurtág an der Central European
        University in Budapest mit dessen „Kafkafragmenten“ für Sopran und Violine. Am "Berliner Ensemble", dem Theater
        von Bertolt Brecht, spielte sie lange Jahre mit und unter der Regie von Manfred Karge Weill's „Dreigroschenoper“
        in der virtuosen Konzertfassung für Violine und Klavier von Stefan Frenkel. Sie wirkte in George Tabori´s
        „Zirkus um Zauberflöte“ mit und in Theaterproduktionen der freien Berliner Theaterszene.
      </p>

      <p>
        <b>Jonathan Golove</b> ist Assoziate Professor für Cello und Komposition an der Universität Buffalo, New York.
        Er studierte in Los Angeles und Berkeley bei Ronald Leonard und Bonnie Hampton und bei Siegfried Palm in Köln,
        sowie Komposition bei Donald Erb und David Felder. Seine rege Konzerttätigkeit führte ihn quer durch die
        Vereinigten Staaten, nach Europa und Mexiko an Konzertorte wie die Carnegie Hall (Weill Recital Hall), die
        Zipper Concert Hall in Los Angeles und London’s Southbank Centre. Seine Vielseitigkeit ist außergewöhnlich. Er
        spielt klassische und Neue Musik, improvisiert auf dem elektrischen Cello und ist einer der wenigen Spezialisten
        für Theremin-Cello. Er spielt in Jazzensembles wie dem Michael Vlatkovich Tryyo und Quartett, dem Ubudis
        Quartett (mit Omar Tamez) oder dem Vinny Golia’s Large Ensemble. Er gastiert bei Festivals wie dem Holland
        Festival, dem Festival d’Automne (Paris), dem Lincoln Center Festival oder June in Buffalo. Aufnahmen und
        Interviews wurden vom WDR, CBC, Radio Nuevo León und Radio France gesendet. Als Komponist bekam er viele
        Auszeichnungen. Seine Werke wurden z.B. bei der Biennale in Venedig, beim Festival von Aix-en-Provence, im
        Lincoln Center und in The Kitchen (New York City) aufgeführt.
      </p>
      <p>
        Die Pianistin <b>Anne-Kathrein Jordan</b> lebt in Lindenbühl im Raum Nürnberg. Seit 1994 arbeitet sie als
        Pädagogin und Korrepetitorin mit jungen Sängern und Instrumentalisten des Windsbacher Knabenchores. Ein
        Schwerpunkt ihrer Arbeit ist die Liedbegleitung. Sie studierte am Konservatorium in Nürnberg und an der
        Hochschule für Musik und Darstellende Kunst in Frankfurt. Ein Gaststudium für Hammerflügel führte sie nach Paris
        an das Conservatoire supérieur. Auf Kursen vertiefte sie ihre Kammermusikerfahrung bei Menahem Pressler, Konrad
        Richter und Emmanuel Hurvitz. Wichtig ist ihr die Arbeit mit zeitgenössischen Komponisten. Sie brachte Werke von
        Wolfgang von Schweinitz, Karola Obermüller und Rainer Pezolt zur Uraufführung und nimmt regelmäßig für den
        Bayerischen Rundfunk auf. Mit großem Spaß tritt Anne-Kathrein Jordan als Pianistin des Frauenkabaretts "Die
        Avantgardinen"auf. Bei Talanton und Bella Musica erschienen CD-Aufnahmen mit Liedern von Werner Bühler und
        Kammermusikwerken für Cello und Klavier von Alfred Schnittke, Leos Janacek, Nadja Boulanger und Robert Schumann.
        Konzertreisen führten sie ins In-und Ausland, Rundfunk-und CD-Aufnahmen runden ihr musikalisches Schaffen ab.
      </p>
      <br />
      <b>Repertoire:</b>
      <table>
        <tbody>
          {repertoire.map((item, key) => {
            if (item.rows && item.rows.length > 0) {
              return (
                <React.Fragment {...{ key }}>
                  {item.rows.map(({ content }, index) => {
                    const artist = index === 0 ? item.head : ''
                    return (
                      <tr key={index}>
                        <TD>{artist}</TD>
                        <TD>{content}</TD>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            } else {
              return (
                <tr {...{ key }}>
                  <TD>
                    <b>{item.head}</b>
                  </TD>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </section>
  )
}

export default Page

export const query = graphql`
  query TRIO {
    picture: allFile(filter: { base: { eq: "trio99.png" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
